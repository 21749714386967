<template>
    <div id='user-list'>
        <v-dialog v-model="table.dialogAdd.open" persistent max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">添加公司</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogAdd.content.name" label="公司名称"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-switch v-model="table.dialogAdd.content.state" label="是否启用" true-value="1"
                                    false-value="2" hide-details></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="table.dialogAdd.content.desc" label="公司描述" hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" outlined @click="table.dialogAdd.open = false"> 取消 </v-btn>
                    <v-btn color="primary" outlined @click="add"> 提交添加 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="table.dialogEdit.open" persistent max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">编辑公司</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogEdit.content.name" label="公司名称"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-switch v-model="table.dialogEdit.content.state" true-value="1" false-value="2"
                                    label="是否启用" hide-details></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="table.dialogEdit.content.desc" label="公司描述" hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" outlined @click="table.dialogEdit.open = false"> 取消 </v-btn>
                    <v-btn color="primary" outlined @click="edit"> 提交更新 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-4'>
                <v-text-field v-model='param.key' class='user-search me-3 mb-0' dense hide-details outlined placeholder='搜索'
                    clearable></v-text-field>
                <v-spacer></v-spacer>
                <v-btn @click="toAdd()" color="primary">新增</v-btn>
            </v-card-text>
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total'>
                <template #[`item.state`]='{ item }'>
                    <v-chip :color="enums.statusColor(item.state)" small>{{ enums.status(item.state) }}</v-chip>
                </template>
                <template #[`item.actions`]='{ item }'>
                    <v-btn @click="toEdit(item)" color="warning" small>编辑信息</v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiSquareEditOutline, } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import companyStoreModule from '../companyStoreModule'
import useCompanyList from './useCompanyList'

export default {
    components: {}, setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-user-company-list'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, companyStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, enums, toAdd, toEdit, add, edit, fetchCompanyList } = useCompanyList()

        fetchCompanyList()

        return {
            headers,
            param,
            table, enums,
            toAdd, toEdit, add, edit,
            fetchCompanyList,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiExportVariant, mdiAccountOutline, },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
