import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useCompanyList() {
    const headers = [
        { text: '单位名称', value: 'name' },
        { text: '状态', value: 'state' },
        { text: '备注', value: 'desc' },
        { text: '创建时间', value: 'create_at' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        query: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: { open: false, msg: '' },
        lodading: false,
        dialogAdd: {
            open: false,
            loading: false,
            content: { name: '', desc: '', state: 2 }
        },
        dialogEdit: {
            open: false,
            loading: false,
            content: { id: '', name: '', desc: '', state: 2 }
        }
    })
    const enums = {
        status: (state) => {
            if (state == 1) return "正常"
            return "禁用"
        },
        statusColor: (state) => {
            if (state == 1) return "success"
            return "error"
        },
    }
    const fetchCompanyList = () => {
        table.value.loading = true
        store.dispatch('app-user-company-list/fetchCompanyList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }
    const toAdd = () => {
        table.value.dialogAdd = {
            open: true,
            loading: false,
            content: { name: '', desc: '', state: 2 }
        }
    }
    const toEdit = (item) => {
        table.value.dialogEdit = {
            open: true,
            loading: false,
            content: item
        }
    }
    const add = () => {
        table.value.loading = true
        const data = { ...table.value.dialogAdd.content }
        store.dispatch('app-user-company-list/addCompany', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogAdd.open = false
            fetchCompanyList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }
    const edit = () => {
        table.value.loading = true
        const data = { ...table.value.dialogEdit.content }
        store.dispatch('app-user-company-list/updateCompany', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogEdit.open = false
            fetchCompanyList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    watch([param], () => {
        fetchCompanyList()
    }, { deep: true })
    return {
        headers,
        param,
        table, enums,
        toAdd, toEdit, add, edit,
        fetchCompanyList
    }
}
