import axios from '@axios'

export default {
    namespaced: true, state: {}, getters: {}, mutations: {}, actions: {

        fetchCompanyList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-company/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams.query }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addCompany(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/admin-company/v1/app/user-company`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        updateCompany(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/app/user-company/${data.id}`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
    },
}
